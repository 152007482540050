import React, {useState} from 'react';
import {Close} from "@app/components/icons/close";
import {useModal} from "@app/components/modal-views/context";
import Button from "@app/components/ui/button";
import {MemberDto, MemberInvitationDto} from "@app/store/project/types";
import {capitalize} from "@app/utils/stringUtils";
import {RoleType} from "@app/models/enums/role";
import {useTranslation} from "next-i18next";
import {router} from "next/client";
import {useRouter} from "next/router";

function RoleModal({member, handleActionButton, newRole}: {
    member: MemberDto | MemberInvitationDto,
    newRole: RoleType,
    handleActionButton: () => void
}) {
    const {closeModal} = useModal();
    const {t} = useTranslation(["modal", "common"]);
    const router = useRouter();
    const title = "userInfo" in member ? t("changeMemberRoleTitle", {email: member.userInfo.email}) : t("changeInvitationRoleTitle", {
        email: member.email,
        newRole: capitalize(t(`roleType.${newRole.toLowerCase()}`, {ns: "common"}))
    })
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div data-testid="role-modal"
             className="flex flex-col bg-white pb-10 gap-6 rounded-[8px] max-w-[466px] w-full ">
            <div className="flex border-b-black-200 border-b-[1px]  justify-between p-4">
                <p className="p2 text-black-800">{t("changeRole", {ns: "common"})}</p>
                <Close onClick={closeModal} className="h-6 cursor-pointer w-6"/>
            </div>
            <div className="flex flex-col gap-6 px-10">
                <div className="flex flex-col gap-2">
                    <p className="h4 text-black-800" dangerouslySetInnerHTML={{__html: title}}></p>
                    <p className="p2 text-black-700" dangerouslySetInnerHTML={{
                        __html: t("changeMemberRoleDescription", {
                            email: "userInfo" in member ? member.userInfo.email : member.email,
                            type: router.query.projectId ? t('project', {ns: "common"}) : t('organization', {ns: "common"}),
                            newRole: capitalize(t(`roleType.${newRole.toLowerCase()}`, {ns: "common"}))
                        })
                    }}></p>
                </div>
                <div className="grid sm:grid-cols-2 sm:grid-rows-1 grid-rows-2 sm:gap-6 gap-4 !max-w-[466px] w-full">
                    <Button className={"rounded-[4px]  w-full hover:!bg-black-1 !bg-black-800  h-[45px]"}
                            variant="solid"
                            onClick={
                                closeModal
                            }>{t("cancel", {ns: "common"})}</Button>
                    <Button
                        className={"rounded-[4px] h-[45px] w-full hover:!bg-blue-600 !bg-blue-500"}
                        variant={"solid"}
                        data-testid="change-role-button"
                        isLoading={isLoading}
                        onClick={() => {
                            setIsLoading(true);
                            handleActionButton();
                        }}
                    >{"userInfo" in member ? t("changeRole", {ns: "common"}) : t("inviteNow", {ns: "common"})}</Button>
                </div>
            </div>
        </div>
    );
}

export default RoleModal;
