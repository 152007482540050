import {createSlice} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {RootState} from '@app/store/store';
import {pdfStateInterface} from '@app/store/pdf/types';

export const initialPdfState: pdfStateInterface = {
    fingerprint: '',
    sortBy: undefined,
    status: undefined,
    query: undefined,
    sortOrder: undefined,
    page: 1,
    size: 10,
    documentStatusTranslation: undefined,
    filterBy: undefined,
    toggleCleared: false,
    selectedRows: [],
    resetPagination: false,
};
export const pdfSlice = createSlice({
    name: 'pdfSlice',
    initialState: initialPdfState,
    reducers: {
        setFingerPrint: (state, action) => {
            return {
                ...state,
                fingerprint: action.payload
            };
        },
        setQuery: (state, action) => {
            return {
                ...state,
                query: action.payload,
                filterBy: undefined,
                toggleCleared: !state.toggleCleared,
                selectedRows: [],
                page: 1,
                resetPagination: !state.resetPagination
            };
        },
        setSortingValue: (state, action) => {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                status: action.payload.status,
                sortOrder: action.payload.sortOrder,
                documentStatusTranslation: action.payload.documentStatusTranslation,
                filterBy: undefined,
                toggleCleared: !state.toggleCleared,
                page: 1,
                resetPagination: !state.resetPagination,
                selectedRows: []
            };
        },
        setPagination: (state, action) => {
            return {
                ...state,
                page: action.payload.page,
                size: action.payload.size ?? state.size,
                toggleCleared: !state.toggleCleared,
                selectedRows: []
            };
        },
        setSortingValueAndQuery: (state, action) => {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                status: action.payload.status,
                sortOrder: action.payload.sortOrder,
                documentStatusTranslation: action.payload.documentStatusTranslation,
                query: action.payload.query,
                filterBy: action.payload.filterBy,
                toggleCleared: !state.toggleCleared,
                page: 1,
                resetPagination: !state.resetPagination,
                selectedRows: []
            };
        },
        resetPdfSliceAndSetQuery: (state, action) => {
            return {
                ...state,
                sortBy: undefined,
                status: undefined,
                sortOrder: undefined,
                documentStatusTranslation: undefined,
                filterBy: undefined,
                query: action.payload,
                resetPagination: !state.resetPagination,
                page: 1,
                toggleCleared: !state.toggleCleared,
                selectedRows: []
            };
        },
        resetQuery: (state) => {
            return {
                ...state,
                query: undefined,
                filterBy: undefined,
            };
        },
        setToggleCleared: (state, action) => {
            return {
                ...state,
                toggleCleared: action.payload,
                selectedRows: []
            }
        },
        setSelectedRows: (state, action) => {
            return {
                ...state,
                selectedRows: action.payload
            }
        },
        resetPdfSlice: (state) => {
            return {
                ...state,
                sortBy: undefined,
                status: undefined,
                sortOrder: undefined,
                query: undefined,
                filterBy: undefined,
                page: 1,
                resetPagination: !state.resetPagination,
                toggleCleared: !state.toggleCleared,
                documentStatusTranslation: undefined,
                selectedRows: []
            };
        }
    }
});

const pdfReducer = persistReducer(
    {
        key: 'rtk:pdfSlice',
        storage,
        whitelist: ['value']
    },
    pdfSlice.reducer
);

const reducerObj = {reducerPath: pdfSlice.name, reducer: pdfReducer};

export const selectFingerPrint = (state: RootState) => state.pdfSlice.fingerprint;

export const selectPdfInfo = (state: RootState) => state.pdfSlice;
export const {setFingerPrint, setSelectedRows, setPagination, setToggleCleared, resetPdfSliceAndSetQuery, setSortingValueAndQuery, setSortingValue, resetQuery, resetPdfSlice, setQuery} = pdfSlice.actions;

export default reducerObj;
