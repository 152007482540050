import {Check} from "@app/components/icons/check";
import Info from "@app/components/icons/info";
import {capitalizeAllFirstAlphabets, isEmptyString} from "@app/utils/stringUtils";
import {toast} from "react-toastify";
import {ToastId} from "@app/constants/ToastId";
import {pdfResponseDto} from "@app/store/pdf/types";

export const getFileSize = (fileSize: number) => {
    const KB = 1000;
    if (fileSize < KB) {
        return `${fileSize} Bytes`;
    }
    if (fileSize >= KB && fileSize < KB * KB) {
        return `${(fileSize / KB).toFixed(2)} KB`;
    }
    if (fileSize >= KB * KB) {
        return `${(fileSize / (KB * KB)).toFixed(2)} MB`;
    }
    return `${(fileSize / (KB * KB * KB)).toFixed(2)} GB`;
};

export const fileToBase64DataURL = (blob: File) =>
    new Promise((resolvePromise) => {
        const reader = new FileReader();
        reader.onload = () => resolvePromise(reader.result);
        reader.readAsDataURL(blob);
    });

export const getTotalSizeOfFiles = (files?: FileList) => {
    let totalSize = 0;
    if (files) {
        for (let i = 0; i < files.length; i++) {
            totalSize += files[i].size;
        }
    }
    return totalSize;
};

export const changeFileArrayToFileList = (files: File[]) => {
    const dataTransfer = new DataTransfer();
    if (files != null) {
        // Add files to the DataTransfer object
        files.forEach((file) => {
            dataTransfer.items.add(file);
        });
    }
    return dataTransfer.files;
}

export const getDocumentTableHeight = (isUploading: boolean, isFilterBy?: string, isSelectedRow?: boolean) => {
    if (isUploading) {
        return "!h-calc-340";
    } else if (isFilterBy && !!isSelectedRow) {
        return "!h-calc-374";
    } else if (isFilterBy || !!isSelectedRow) {
        return "!h-calc-324";
    } else {
        return "!h-tableHeight";
    }
}

export const isSupportedFile = (fileType: string) => {
    const supportedFileType = ["application/pdf",
        "application/x-pdf",
        "application/x-bzpdf",
        "application/x-gzpdf", "image/", "application/doc",
        "application/ms-doc",
        "application/msword",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]
    return supportedFileType.filter(supportedFileType => fileType.includes(supportedFileType)).length > 0;
}

export const extractExtensionFromFileName = (fileName: string) => {
    const lastDotIndex = fileName?.lastIndexOf('.');
    if (lastDotIndex > 0) {
        return fileName?.substring(lastDotIndex + 1);
    } else {
        return "pdf"; // No extension found
    }
}

export const appendPdfExtensionToFile = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex > 0) {
        return fileName.substring(0, lastDotIndex) + ".pdf";
    }
    return fileName + ".pdf"
}

export const getConfidenceProps = (confidenceLabel: number) => {
    if (Math.round(confidenceLabel) > 0.8) {
        return {
            status: 'confidenceStatus.accurate',
            color: "bg-[#C4F1D7] text-[#108956]",
            icon: Check
        }
    }
    return {
        status: 'confidenceStatus.lessCertain',
        color: "bg-[#FFEFCF] text-[#D68604]",
        icon: Info
    }
}

export const makeExtractedValueReadable = (value: string) => {
    if (isEmptyString(value)) {
        return "--";
    }
    return value.replaceAll("\n", " ");
}
export const makeExtractedLabelReadable = (label: string) => {
    const labelWithoutUnderScore = label.replaceAll("_", " ");
    return capitalizeAllFirstAlphabets(labelWithoutUnderScore);
}

export const createAndTriggerDownload = (blob: Blob, fileName: string, downloadToastMessage: string) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    toast.success(downloadToastMessage, {toastId: ToastId.DOCUMENT_SUCCESS_TOAST});
};

export const isDocumentBusy = (row: pdfResponseDto) => {
    const lastHeartBeatReceived = new Date(row.lastReceivedHeartbeat);

    const currentTime = new Date();

    // @ts-ignore
    const differenceInTime = currentTime - lastHeartBeatReceived;

    const twoMinutesInMillis = 60 * 1000; // if heartbeat has arrived within a minute

    return differenceInTime < twoMinutesInMillis;

}

export const hasAnnotationFinished = (row: pdfResponseDto) => {
    return row.status !== DocumentStatus.Uploading
        && row.status !== DocumentStatus.UploadingSuccess
        && row.status !== DocumentStatus.UploadingFailure
        && row.status !== DocumentStatus.CorruptDocument
        && row.status !== DocumentStatus.Unsupported
        && row.status !== DocumentStatus.Validating
        && row.status !== DocumentStatus.ValidationSuccess
        && row.status !== DocumentStatus.ValidationFailure
        && row.status !== DocumentStatus.ConvertingDocumentToPdf
        && row.status !== DocumentStatus.ConvertingDocumentToPdfFailure
        && row.status !== DocumentStatus.ConvertingDocumentToPdfSuccess
        && row.status !== DocumentStatus.Ocr
        && row.status !== DocumentStatus.OcrSuccess
        && row.status !== DocumentStatus.OcrFailure
        && row.status !== DocumentStatus.OcrQueued
        && row.status !== DocumentStatus.Annotating
        && row.status !== DocumentStatus.AnnotationFailed
};

export const hasDocumentConversionFinished = (row: pdfResponseDto) => {
    return row.status === "document.status.uploading_failure"
        || row.status === "document.status.converting_to_pdf"
        || row.status === "document.status.converting_to_pdf_success"
        || row.status === "document.status.validating"
        || row.status === "document.status.validation_success"
        || row.status === "document.status.validation_failure"
        || row.status === "document.status.uploading"
        || row.status === "document.status.uploading_failure"
        || row.status === "document.status.uploading_success"
}

export const isPdfDocument = (name: string) => {
    return name.endsWith(".pdf")
};

export const shouldDisableEdit = (row: pdfResponseDto) => {
    return row.status === DocumentStatus.Anonymizing
        || row.status === DocumentStatus.Saving
        || row.status === DocumentStatus.CreatingNewDocument
}

export enum DocumentStatus {
    Annotating = "document.status.annotating",
    Annotated = "document.status.annotated",
    AnnotationFailed = "document.status.annotation_failed",
    Anonymizing = "document.status.anonymizing",
    AnonymizationFailed = "document.status.anonymization_failed",
    Anonymized = "document.status.anonymized",
    Saving = "document.status.saving",
    SavingFailed = "document.status.saving_failed",
    Saved = "document.status.saved",
    CreatingNewDocument = "document.status.creating_new_document",
    CreatingNewDocumentFailed = "document.status.creating_new_document_failed",
    CreatedNewDocument = "document.status.created_new_document",
    SentForAnonymization = "document.response.sentForAnonymization",
    SentForSaving = "document.response.sentForSaving",
    CorruptDocument = "document.status.corrupt_document",
    ConvertingDocumentToPdf = "document.status.converting_to_pdf",
    ConvertingDocumentToPdfSuccess = "document.status.converting_to_pdf_success",
    ConvertingDocumentToPdfFailure = "document.status.converting_to_pdf_failed",
    Uploading = "document.status.uploading",
    UploadingFailure = "document.status.uploading_failure",
    UploadingSuccess = "document.status.uploading_success",
    Ocr = "document.status.ocr",
    OcrQueued = "document.status.queued",
    OcrFailure = "document.status.ocr_failure",
    OcrSuccess = "document.status.ocr_success",
    Validating = "document.status.validating",
    ValidationSuccess = "document.status.validation_success",
    ValidationFailure = "document.status.validation_failure",
    Unsupported = "document.status.unsupported"
}

export const downloadFile = (downloadUrl: string) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    // Create a new link element
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.target = '_blank';

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the click event
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
};
