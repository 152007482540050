import React from 'react';
import {TextField, TextFieldProps} from '@mui/material';

function PdfEditorTextField(props: TextFieldProps) {
    return (
        <TextField
            InputProps={{
                sx: {
                    height: 48
                }
            }}
            {...props}
        />
    );
}

export default PdfEditorTextField;
