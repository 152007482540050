import Button from "@app/components/ui/button";
import React, {useEffect, useRef, useState} from "react";
import {getFileSize} from "@app/utils/pdfUtils";
import DocumentLogoSelector from "@app/components/ui/document-logo-selector";
import {useRouter} from "next/router";
import {useModal} from "@app/components/modal-views/context";
import {useTranslation} from "next-i18next";
import {toast} from "react-toastify";
import UploadIcon from "@app/components/icons/upload-icon";
import {Close} from "@app/components/icons/close";
import {toMidDottedStr} from "@app/utils/stringUtils";
import TusFileUploader from "@app/configs/tus-file-uploader";
import environments from "@app/configs/environments";

export type Status = 'CONVERSION' | 'OCR' | 'ANNOTATION';

interface UploadRequest {
    file: File | null;
    status: Status;
}

export default function CustomUploadDocumentModal({documentName, documentId}: {
    documentName: string;
    documentId: string;
}) {

    const inputFileRef = useRef(null);
    const router = useRouter();

    const {t} = useTranslation(["document", "common"]);

    const {closeModal} = useModal();

    const [uploadRequest, setUploadRequest] = useState<UploadRequest>({
        file: null,
        status: 'CONVERSION'
    });

    const endpoint = `${environments.CLIENT_SIDE_ENDPOINT_HOST}/api/organizations/${router.query.organizationId}/projects/${router.query.projectId}/documents/${documentId}/resume/tus`;
    const fileUploader = new TusFileUploader(
        endpoint,
        environments,
        {
            status: uploadRequest.status,
            filename: !!uploadRequest.file ? uploadRequest.file.name : ""
        });

    const [progress, setProgress] = useState<number>(0);

    const [isUploading, setIsUploading] = useState(false);


    const handleUploadSuccess = () => {
        setIsUploading(false);
        closeModal()
        toast(`${t('toast.pdfAddedSuccessfully', {ns:"common"})}`, {type: "success"});
    };

    const handleUploadError = (error: { name: string; message: string; details?: string }) => {
        console.error("error when uploading: ", error);
        toast(`${t('toast.somethingWentWrong', {ns: "common"})}`, {type: 'error', toastId: "MANUAL_UPLOAD_ERROR"});
        setIsUploading(false);
    };

    const handleUploadProgress = (progress: any) => {
        const percentage = Math.floor((progress.uploaded / progress.total) * 100);
        setProgress(percentage);
    }

    const handleSubmit = async () => {
        setIsUploading(true);
        await fileUploader.uploadFiles(uploadRequest.file);
    };

    const handleUploadButton = () => {
        // @ts-ignore
        inputFileRef.current?.click();
    };

    const handleUploadFile = (files: FileList | null) => {
        if (!!files && files?.length > 0) {
            setUploadRequest({...uploadRequest, file: files[0]})
        }
    }

    const handleCancel =  () => {
        fileUploader.cancelAllUploads();
        setIsUploading(false);
    }

    const handleDialogClose =  () => {
        fileUploader.cancelAllUploads();
        closeModal();
    }

    const handleSelectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setUploadRequest({...uploadRequest, status: event.target.value as Status})
    }

    useEffect(() => {
        if (!!fileUploader) {
            fileUploader.configureUppy(
                handleUploadSuccess,
                handleUploadError,
                handleUploadProgress
            );
        }
    }, [fileUploader]);

    return (
        <div
            className="flex flex-col bg-white p-10 gap-6 rounded-[8px] max-w-[466px] w-full ">
            <h1 className={"border-b-[2px] border-solid pb-2 "}>
                {t('uploadProcessedDocument')} <b>{documentName}</b>
            </h1>

            <label htmlFor="requestType" className={"!mb-0"}>{t('selectNextStep')}</label>
            <select
                id="requestType"
                value={uploadRequest.status}
                onChange={handleSelectionChange}
                className=" block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-0"
            >
                <option value="CONVERSION">{t('CONVERSION')}</option>
                <option value="OCR">{t('OCR')}</option>
                <option value="ANNOTATION">{t('ANNOTATION')}</option>
            </select>

            <h2>{t('uploads')}</h2>
            {!!uploadRequest.file ?
                <div
                    className={"p-2 border-[2px] border-gray-100 rounded-md border-solid w-full flex justify-between items-start"}>
                    <div className={"flex items-center"}>
                        <DocumentLogoSelector size={"8"} fileName={uploadRequest?.file?.name}/>
                        <div className={"ml-2 w-full max-w-full max-h-full"}>
                            <h3>{toMidDottedStr(uploadRequest?.file?.name, 10)}</h3>
                            <p className={"text-sm text-gray-600"}>{getFileSize(uploadRequest?.file?.size)}</p>
                        </div>
                    </div>
                    <Close className={"h-4 w-4 cursor-pointer"}
                           onClick={() => {
                               if (isUploading) return;
                               setUploadRequest({...uploadRequest, file: null})
                           }}/>
                </div> :
                <div
                    className={"w-full border-dotted flex flex-col justify-center items-center rounded-md border-[2px] p-10 cursor-pointer"}
                    onClick={handleUploadButton}>
                    <UploadIcon/>
                    <p className={"mt-2"}>{t("uploadDocument", {ns: "common"})}</p>
                </div>
            }
            {isUploading && <div className="relative text-white h-[12px] w-full rounded-[6px] bg-white-4">
                <div
                    className="absolute left-0 top-0 flex justify-center text-sm items-center h-full rounded-[6px] bg-brand-2"
                    style={{width: progress + "%"}}>{progress}%
                </div>
            </div>}

            {isUploading ?
                <Button className={"w-full !bg-black-1"} onClick={handleCancel}>Cancel</Button>:
                <Button isLoading={isUploading} disabled={isUploading} variant={"outline"}
                        className={"w-full"} onClick={handleSubmit}>{t('submit')}</Button>}

            <div className="absolute right-3 top-3 cursor-pointer text-gray-600 hover:text-black"
                 onClick={handleDialogClose}>
                <Close className=" h-6 w-6 text-black-2 dark:text-white"/>
            </div>
            <input ref={inputFileRef} hidden type="file"
                   onChange={(e) => handleUploadFile(e.target.files)}/>
        </div>
    );
}