import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import environments from '@app/configs/environments';
import { AccessTokenRequestBody, AuthProps } from '@app/store/auth/types';

export const USER_PATH = 'otp';

export const otpApi = createApi({
    reducerPath: USER_PATH,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    keepUnusedDataFor: 0,
    baseQuery: fetchBaseQuery({
        baseUrl: environments.CLIENT_SIDE_ENDPOINT_HOST + '/api/otp',
        credentials: 'include',
        prepareHeaders(headers) {
            headers.set('Access-Control-Allow-Origin', environments.CLIENT_SIDE_ENDPOINT_HOST as string);
            return headers;
        }
    }),
    endpoints: (builder) => ({
        sendOtp: builder.mutation<any, { email: string }>({
            query: (requestBody) => ({
                url: '/send',
                method: 'POST',
                body: JSON.stringify(requestBody)
            })
        }),
        postVerifyOtp: builder.mutation<any, { email: string; otpcode: number }>({
            query: (requestBody) => ({
                url: '/verify',
                method: 'POST',
                body: JSON.stringify(requestBody)
            })
        })
    })
});

export const { usePostVerifyOtpMutation, useSendOtpMutation } = otpApi;
