import React, {useState} from 'react';
import {Close} from "@app/components/icons/close";
import {useModal} from "@app/components/modal-views/context";
import {useTranslation} from "next-i18next";
import SearchInput from "@app/components/ui/input/search-input";
import cn from "classnames";
import {useAppDispatch, useAppSelector} from "@app/store/hooks";
import {selectPdfInfo, setSortingValueAndQuery} from "@app/store/pdf/slice";

function FilterModal({attributes, sortBy, documentStatusTranslation}: { attributes: Array<string>, sortBy: string, documentStatusTranslation?: Map<string, string> }) {
    const {closeModal} = useModal();
    const {t} = useTranslation(['document', "modal", "common"]);
    const [sortedAttributes, setSortedAttributes] = useState<Array<string>>(attributes);
    const dispatch = useAppDispatch();

    const pdfState = useAppSelector(selectPdfInfo);
    const handleSearch = (event: any) => {
        event.preventDefault();
        const query = event.target.value.trim();
        setSortedAttributes(sortedAttributes.filter(attribute => attribute.toLowerCase().includes(query.toLowerCase())));
    };

    const handleTargetOnclick = (attribute: string) => {
        dispatch(setSortingValueAndQuery({
            ...pdfState,
            sortBy: sortBy.toLowerCase(),
            query: attribute,
            documentStatusTranslation: documentStatusTranslation,
            filterBy: sortBy.toLowerCase()
        }));
        closeModal();
    }
    return (
        <div className="flex flex-col bg-white p-6 gap-8 rounded-[8px] max-w-[466px] w-full ">
            <div className="flex  justify-between">
                <p className="h4 text-black-800">{t("sortingType.filterBy")} {sortBy}</p>
                <Close onClick={closeModal} className="h-6 cursor-pointer w-6"/>
            </div>
            <SearchInput placeholder={t('placeholder.filter', {ns: "modal"})} handleSearch={handleSearch}/>
            <div
                className={cn("flex flex-col gap-2 justify-center ", sortedAttributes.length === 0 && "min-h-[100px]")}>
                {
                    sortedAttributes.length !== 0 ? sortedAttributes.map(attribute => {
                        return <div key={attribute} onClick={() => handleTargetOnclick(attribute)}
                                    className="flex gap-2 p-2 hover:bg-white-2 cursor-pointer items-center">
                            <p className="p1 text-black-700">{attribute}</p>
                        </div>
                    }) : <p className="text-center p1">{t('notFound', {ns: "common"})}</p>
                }
            </div>
        </div>
    );
}

export default FilterModal;