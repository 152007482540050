import {createSlice} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {RootState} from "@app/store/store";
import {ActiveSelectionInterface} from "@app/store/active/types";


export const initialActiveSelectionState: ActiveSelectionInterface = {
    activeOrganization: null,
    activeProject: null
}


export const activeSlice = createSlice({
    name: 'activeSlice',
    initialState: initialActiveSelectionState,
    reducers: {
        setActiveOrganization: (state, action) => {
            return {
                ...state,
                activeOrganization: action.payload
            }
        },
        setActiveProject: (state, action) => {
            return {
                ...state,
                activeProject: action.payload
            }
        }
    }
})

const activeSelectionReducer = persistReducer(
    {
        key: "rtk:activeSlice",
        storage
    },
    activeSlice.reducer
);

const reducerObj = {reducerPath: activeSlice.name, reducer: activeSelectionReducer};

export const selectActiveOrganization = (state: RootState) => state.activeSlice.activeOrganization;

export const selectActiveProject = (state: RootState) => state.activeSlice.activeProject;

export const {setActiveOrganization, setActiveProject} = activeSlice.actions;

export default reducerObj;