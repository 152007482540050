import React, {useState} from 'react';
import {Close} from "@app/components/icons/close";
import Button from "@app/components/ui/button";
import {useModal} from "@app/components/modal-views/context";
import {useAppSelector} from "@app/store/hooks";
import {selectActiveOrganization, selectActiveProject} from "@app/store/active/slice";
import {useTranslation} from "next-i18next";
import {router} from "next/client";
import {useRouter} from "next/router";

interface IRemoveInvitation {
    email: string;
    handleRemoveInvitation: () => void;
}

function RemoveInvitation({handleRemoveInvitation, email}: IRemoveInvitation) {
    const {closeModal} = useModal();
    const {t} = useTranslation(["modal", "common"]);
    const activeProject = useAppSelector(selectActiveProject);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const [isLoading, setIsLoading] = useState(false);
    const router = useRouter();
    return (
        <div data-testid="remove-invitation-modal"
             className="flex flex-col bg-white pb-10 gap-6 rounded-[8px] max-w-[466px] w-full ">
            <div className="flex border-b-black-200 border-b-[1px]  justify-between p-4">
                <p className="p2 text-black-800">{t("removeInvitation")}</p>
                <Close onClick={closeModal} className="h-6 cursor-pointer w-6"/>
            </div>
            <div className="flex flex-col gap-6 px-10">
                <div className="flex flex-col gap-2">
                    <p dangerouslySetInnerHTML={{
                        __html: t("areYouSureYouWantToRemoveInvitation", {
                            email,
                            name: router.query.projectId ?
                                activeProject?.projectName : activeOrganization?.name
                        })
                    }} className="h4 text-black-800">
                    </p>
                </div>
                <div className="grid sm:grid-cols-2 sm:grid-rows-1 grid-rows-2 sm:gap-6 gap-4 !max-w-[466px] w-full">
                    <Button className={"rounded-[4px]  w-full hover:!bg-black-1 !bg-black-800  h-[45px]"}
                            variant="solid"
                            onClick={
                                closeModal
                            }>{t("cancel", {ns: "common"})}</Button>
                    <Button
                        isLoading={isLoading}
                        className={"rounded-[4px] h-[45px] w-full hover:!bg-red-500 !bg-red-300"}
                        variant={"solid"}
                        data-testid="remove-invitation"
                        onClick={() => {
                            setIsLoading(true);
                            handleRemoveInvitation();
                        }
                        }
                    >{t("removeNow", {ns: "common"})}</Button>
                </div>
            </div>
        </div>
    );
}

export default RemoveInvitation;
