import React from 'react';

function UploadIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="38" cy="38" r="38" fill="#DEEDFF" />
            <circle cx="38" cy="38" r="30" fill="url(#paint0_linear_249_583)" />
            <path
                d="M36 50C36 51.1046 36.8954 52 38 52C39.1046 52 40 51.1046 40 50L36 50ZM39.4142 24.5858C38.6332 23.8047 37.3668 23.8047 36.5858 24.5858L23.8579 37.3137C23.0768 38.0948 23.0768 39.3611 23.8579 40.1421C24.6389 40.9232 25.9052 40.9232 26.6863 40.1421L38 28.8284L49.3137 40.1421C50.0948 40.9232 51.3611 40.9232 52.1421 40.1421C52.9232 39.3611 52.9232 38.0948 52.1421 37.3137L39.4142 24.5858ZM40 50L40 26L36 26L36 50L40 50Z"
                fill="#FBFBFB"
            />
            <defs>
                <linearGradient id="paint0_linear_249_583" x1="19.25" y1="14.4773" x2="54.7045" y2="62.8864" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2A89F6" />
                    <stop offset="1" stopColor="#117BF4" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default UploadIcon;
