import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';

import environments from '@app/configs/environments';
import {configurationRequestBody, parameterRequestBody} from "@app/store/config/types";


export const CONFIG_PATH = 'config';
const WHITELIST_TAG = "WHITELIST_TAG";
const BLACKLIST_TAG = "BLACKLIST_TAG";
const PARAMETER_TAG = "PARAMETER_TAG";

export const configApi = createApi({
    reducerPath: CONFIG_PATH,
    tagTypes: [WHITELIST_TAG, BLACKLIST_TAG, PARAMETER_TAG],
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    keepUnusedDataFor: 0,
    baseQuery: fetchBaseQuery({
        baseUrl: environments.CLIENT_SIDE_ENDPOINT_HOST + '/api',
        credentials: "include",
        prepareHeaders(headers, api) {
            headers.set('Access-Control-Allow-Origin', environments.CLIENT_SIDE_ENDPOINT_HOST as string);
            if ((api.endpoint === 'putBlacklist' || api.endpoint === "putWhitelist") && headers.get('Content-Type') !== 'application/json') {
                // headers.set('Content-Type', 'multipart/form-data');
            } else {
                headers.set("Content-Type", "application/json");
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        putBlacklist: builder.mutation<void, configurationRequestBody>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/config/blacklist`,
                method: 'PUT',
                body: requestBody.formData,
            }),
            invalidatesTags: [BLACKLIST_TAG]
        }),
        getWhiteList: builder.query<Blob, { organizationId: string; projectId: string; }>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/config/whitelist`,
                method: "GET",
                responseHandler: async (response) => await response.blob(),
                cache: "no-cache",
            }),
            providesTags: [WHITELIST_TAG]
        }),
        putWhitelist: builder.mutation<void, configurationRequestBody>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/config/whitelist`,
                method: 'PUT',
                body: requestBody.formData
            }),
            invalidatesTags: [WHITELIST_TAG]
        }),
        getBlackList: builder.query<Blob, { organizationId: string; projectId: string; }>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/config/blacklist`,
                method: "GET",
                responseHandler: async (response) => await response.blob(),
                cache: "no-cache",
            }),
            providesTags: [BLACKLIST_TAG]
        }),
        putParameters: builder.mutation<void, parameterRequestBody>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/config/parameters`,
                method: 'PUT',
                body: requestBody.body
            }),
            invalidatesTags: [PARAMETER_TAG]
        }),
        getParameters: builder.query<Object, {organizationId: string; projectId: string;}>({
            query: (requestBody) => `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/config/parameters`,
            providesTags: [PARAMETER_TAG]
        }),
    })
});

export const {
    usePutBlacklistMutation,
    usePutWhitelistMutation,
    useGetWhiteListQuery,
    useGetBlackListQuery,
    useGetParametersQuery,
    usePutParametersMutation
} = configApi;
