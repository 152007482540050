import React from 'react';
import Image from "next/image";
import LoadingImage from "@app/assets/image/loader.gif";
import {useTranslation} from "next-i18next";

function LoadingModal({title}: { title: string }) {
    const {t} = useTranslation(["common"]);
    return (
        <div className="flex px-6 flex-col justify-center gap-10 items-center">
            <Image src={LoadingImage} objectFit="fill" height={50} width={50}/>
            <div className="flex flex-col gap-1 items-center">
                <p className="h2 text-white">{title}</p>
                <p className="p2 text-white">{t("pleaseWaitAMoment")}</p>
            </div>
        </div>
    );
}

export default LoadingModal;
