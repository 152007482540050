import React from 'react';

function WarningIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="66" height="46" viewBox="0 0 66 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <ellipse cx="33" cy="41" rx="33" ry="5" fill="#CECECE" />
            <path d="M30.411 4.42295C31.5696 2.44366 34.4304 2.44367 35.589 4.42295L54.3568 36.4845C55.5275 38.4844 54.0851 41 51.7677 41H14.2323C11.9149 41 10.4725 38.4844 11.6432 36.4845L30.411 4.42295Z" fill="white" stroke="#FF3636" strokeWidth="2" />
            <path d="M33 14V27" stroke="#FF3636" strokeWidth="2" strokeLinecap="round" />
            <path d="M33 31L33 32" stroke="#FF3636" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
}

export default WarningIcon;
