import React, {useState} from 'react';
import {Close} from "@app/components/icons/close";
import PdfEditorTextField from "@app/components/ui/input/pdf-editor-text-field";
import Button from "@app/components/ui/button";
import {useModal} from "@app/components/modal-views/context";
import {useTranslation} from "next-i18next";
import {toast} from "react-toastify";
import {ToastId} from "@app/constants/ToastId";

interface IDeleteProject {
    projectName: string;
    handleDeleteProject: () => void;
}

function DeleteProjectModal({projectName, handleDeleteProject}: IDeleteProject) {
    const {closeModal} = useModal();
    const {t} = useTranslation(['common', 'modal']);
    const [isLoading, setIsLoading] = useState(false);
    const [inputProjectName, setInputProjectName] = useState('');
    const handleOnChange = (e: any) => {
        setInputProjectName(e.target.value);
    }
    const handleProjectDeletionButtonClick = () => {
        setIsLoading(true);
        if (inputProjectName.toLowerCase() === projectName.toLowerCase()) {
            handleDeleteProject();
            return;
        }
        toast.error(`${t('toast.failedToDeletePdf')}`, {toastId: ToastId.PROJECT_ERROR_TOAST});
        closeModal();
    }
    return (
        <div data-testid="delete-project-modal"
             className="flex flex-col bg-white pb-10 gap-6 rounded-[8px] max-w-[466px] w-full ">
            <div className="flex border-b-black-200 border-b-[1px]  justify-between p-4">
                <p className="p2 text-black-800">{t("deleteProject")}</p>
                <Close onClick={closeModal} className="h-6 cursor-pointer w-6"/>
            </div>
            <div className="flex flex-col gap-6 px-10">
                <div className="flex flex-col gap-2">
                    <p className="h4 text-black-800">{t("areYouSureYouWantToDelete", {ns: "modal"})} <span
                        className="font-bold">{projectName}</span>?</p>
                    <p className="p2 text-black-700">{t("projectDeletionDescription", {ns: "modal"})}</p>
                </div>
                <div className="flex flex-col gap-2">
                    <label className="h4 text-black-800">{t("label.enterProjectName")}</label>
                    <PdfEditorTextField data-testid="project-name-input" onChange={handleOnChange}
                                        placeholder={t("placeholder.projectName")}
                                        className="!h-12"/>
                </div>
                <div className="grid sm:grid-cols-2 sm:grid-rows-1 grid-rows-2 sm:gap-6 gap-4 !max-w-[466px] w-full">
                    <Button className={"rounded-[4px]  w-full hover:!bg-black-1 !bg-black-800  h-[45px]"}
                            variant="solid"
                            onClick={
                                closeModal
                            }>{t("cancel")}</Button>
                    <Button data-testid="delete-now" isLoading={isLoading}
                        // disabled={inputProjectName.toLowerCase() !== projectName.toLowerCase()}
                            className={"rounded-[4px] h-[45px] w-full hover:!bg-red-500 !bg-red-300"}
                            variant={"solid"}
                            onClick={handleProjectDeletionButtonClick}
                    >{t("deleteNow")}</Button>
                </div>
            </div>
        </div>
    );
}

export default DeleteProjectModal;
