import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';

import environments from '@app/configs/environments';
import {UpdateInvitationProps} from "@app/store/invitation/types";


export const INVITATION_PATH = 'invitation';


export const invitationApi = createApi({
    reducerPath: INVITATION_PATH,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    keepUnusedDataFor: 0,
    baseQuery: fetchBaseQuery({
        baseUrl: environments.CLIENT_SIDE_ENDPOINT_HOST + '/api',
        credentials: 'include',
        prepareHeaders(headers) {
            headers.set('Access-Control-Allow-Origin', environments.CLIENT_SIDE_ENDPOINT_HOST as string);
            headers.set("Content-Type", "application/json");
            return headers;
        }
    }),
    endpoints: (builder) => ({
        updateAllInvitation: builder.mutation<any, UpdateInvitationProps>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/invitations/${requestBody.invitationToken}/all`,
                method: 'POST',
                params: {
                    "invitationStatus": requestBody.invitationStatus
                }
            })
        })
    })
});

export const {
    useUpdateAllInvitationMutation
}
    = invitationApi;
