import React from 'react';

function LogoIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M23.0008 5L23 5.00098L33 17.0015V31C33 33.2091 31.2091 35 29 35H11C8.79086 35 7 33.2091 7 31V9C7 6.79086 8.79086 5 11 5H23.0008Z" fill="#0076FC" />
            <path d="M23 17V5L33 17H23Z" fill="#78B7FF" />
            <path d="M7 22H33V31C33 33.2091 31.2091 35 29 35H11C8.79086 35 7 33.2091 7 31V22Z" fill="#1351C3" />
            <path
                d="M11.7191 32V25.4545H14.0522C14.5614 25.4545 14.9833 25.5472 15.3178 25.7326C15.6523 25.918 15.9027 26.1715 16.0689 26.4933C16.2351 26.8129 16.3182 27.1729 16.3182 27.5735C16.3182 27.9762 16.234 28.3384 16.0657 28.6602C15.8995 28.9798 15.6481 29.2333 15.3114 29.4208C14.9769 29.6062 14.5561 29.6989 14.049 29.6989H12.4446V28.8615H13.9595C14.2813 28.8615 14.5423 28.8061 14.7425 28.6953C14.9428 28.5824 15.0898 28.429 15.1836 28.2351C15.2773 28.0412 15.3242 27.8207 15.3242 27.5735C15.3242 27.3263 15.2773 27.1069 15.1836 26.9151C15.0898 26.7234 14.9418 26.5732 14.7393 26.4645C14.5391 26.3558 14.2749 26.3015 13.9467 26.3015H12.7067V32H11.7191ZM19.5949 32H17.4759V25.4545H19.662C20.3034 25.4545 20.8541 25.5856 21.3144 25.8477C21.7746 26.1076 22.1272 26.4815 22.3722 26.9695C22.6194 27.4553 22.743 28.038 22.743 28.7177C22.743 29.3995 22.6183 29.9854 22.3691 30.4755C22.1219 30.9656 21.7639 31.3427 21.2952 31.6069C20.8264 31.869 20.2597 32 19.5949 32ZM18.4635 31.1371H19.5406C20.0392 31.1371 20.4536 31.0433 20.7838 30.8558C21.1141 30.6662 21.3612 30.3924 21.5253 30.0344C21.6894 29.6744 21.7714 29.2354 21.7714 28.7177C21.7714 28.2042 21.6894 27.7685 21.5253 27.4105C21.3634 27.0526 21.1215 26.7809 20.7998 26.5955C20.4781 26.4102 20.0786 26.3175 19.6013 26.3175H18.4635V31.1371ZM23.9711 32V25.4545H28.03V26.3047H24.9586V28.299H27.7392V29.146H24.9586V32H23.9711Z"
                fill="#FBFBFB"
            />
        </svg>
    );
}

export default LogoIcon;
