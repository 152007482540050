import React, {useMemo} from 'react';

import {debounce} from 'lodash';

import {InputAdornment, TextFieldProps} from '@mui/material';
import PdfEditorTextField from '@app/components/ui/input/pdf-editor-text-field';
import SearchIcon from '@app/components/icons/search-icon';

interface ISearchInputProps {
    handleSearch: (event: any) => void;
}

export default function SearchInput({
                                        handleSearch,
                                        className,
                                        placeholder, id
                                    }: TextFieldProps & ISearchInputProps) {
    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
    }, []);
    return (
        <PdfEditorTextField
            data-testid={id}
            onChange={debouncedResults}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon className="cursor-pointer"/>
                    </InputAdornment>
                ),
                sx: {
                    height: 48,
                    paddingX: '12px',
                    paddingY: '12px',
                    backgroundColor: 'white'
                }
            }}
            placeholder={placeholder}
            className={`w-0  border-white-4 !rounded-md opacity-0 sm:w-auto sm:opacity-100 ${className} `}
        />
    );
}
